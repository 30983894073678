import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ProSidebarProvider } from 'react-pro-sidebar';
import axios from 'axios';
import {PublicClientApplication} from "@azure/msal-browser";
import {MsalProvider} from "@azure/msal-react";
import { config,msalConfig,loginApiRequest } from './config';


const msalInstance = new PublicClientApplication(msalConfig);
await msalInstance.initialize();
let accessToken = null;
const redirectResponse = await msalInstance.handleRedirectPromise();
if (redirectResponse !== null) {
  // Acquire token silent success
  accessToken = redirectResponse.accessToken;
  // Call your API with token
  
} else {
  // MSAL.js v2 exposes several account APIs, logic to determine which account to use is the responsibility of the developer
  const account = msalInstance.getAllAccounts()[0];

    msalInstance
    .acquireTokenSilent(loginApiRequest)
    .then(function (accessTokenResponse) {
      // Acquire token silent success
      // Call API with token
      accessToken = accessTokenResponse.accessToken;
      
    })
    .catch(function (error) {
      //Acquire token silent failure, and send an interactive request
     // console.log(error);
     // if (error instanceof InteractionRequiredAuthError) {
       // console.log('inside error block',msalInstance.acquireTokenRedirect(loginApiRequest));
        accessToken=msalInstance.acquireTokenRedirect(loginApiRequest);
      //}
    });
}
//accessToken=msalInstance.acquireTokenRedirect(loginApiRequest);
const api1 = axios.create(
  {
          baseURL: config.SERVER_URL
    });
    //accessToken='eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6IjlHbW55RlBraGMzaE91UjIybXZTdmduTG83WSIsImtpZCI6IjlHbW55RlBraGMzaE91UjIybXZTdmduTG83WSJ9.eyJhdWQiOiJhcGk6Ly8zMzRhMTczZi05ZDY1LTQwMjItYjFkMi1iOTFjZTgxM2Q3MTAvcHJvcGVydGllcyIsImlzcyI6Imh0dHBzOi8vc3RzLndpbmRvd3MubmV0LzhkZDBmZWJlLWZhOTktNDVhNy1iZThjLTJjMDBjNGU5YzljOS8iLCJpYXQiOjE3MDAxNzcwMjIsIm5iZiI6MTcwMDE3NzAyMiwiZXhwIjoxNzAwMTg4MTIyLCJhaW8iOiJFMlZnWUZnODRVSjlsVGV2K1A5RE8rOGZkTjZtQUFBPSIsImFwcGlkIjoiMzM0YTE3M2YtOWQ2NS00MDIyLWIxZDItYjkxY2U4MTNkNzEwIiwiYXBwaWRhY3IiOiIxIiwiaWRwIjoiaHR0cHM6Ly9zdHMud2luZG93cy5uZXQvOGRkMGZlYmUtZmE5OS00NWE3LWJlOGMtMmMwMGM0ZTljOWM5LyIsIm9pZCI6IjNmODAyMmVjLThmNTctNDg1OS04OTM3LWU5Zjg4YTJmNWQ4NCIsInJoIjoiMC5BUWNBdnY3UWpabjZwMFctakN3QXhPbkp5VDhYU2pObG5TSkFzZEs1SE9nVDF4QUhBQUEuIiwic3ViIjoiM2Y4MDIyZWMtOGY1Ny00ODU5LTg5MzctZTlmODhhMmY1ZDg0IiwidGlkIjoiOGRkMGZlYmUtZmE5OS00NWE3LWJlOGMtMmMwMGM0ZTljOWM5IiwidXRpIjoibXVWRjJ1LU9NRWEyYjhOSTJaZ3VBQSIsInZlciI6IjEuMCJ9.E0r9K6bBmTogZfRoEobACqqYbkQVAUKcqHhAqY1QI5Cj2qJQ1meSUOjwtdE6axq0jE0V7O2sAvi_z8YcwORqg36GdnbgCIt_Rf1DLGM48KgJEwduWORhX4M9EWv7v4XqQUP_EvDFN9_WS_03pI9yQLAPNLr8X-f2Vd_YjaRawwyVW_jvHK3-2d2HfKVqBz_STRg-l5odbOyNUpt8wC7BfYFZA1TEMSY9ueOX6GrDXCSzM7c69FPMZKWtUC0Hwu9JYRWNHHlXdn84nwqwdJYb7-eOOOV97WJ6MJpulz7hS8r9OJ9dkj94VOMdTZjYoua4hhqzeXicctEbkI_K5SUBBA';
    //if(accessToken){

    console.log('Response accesstoknen',accessToken);
    api1.interceptors.request.use(
      (config) => {
        
        if (accessToken) {
          config.headers.Authorization = `Bearer ${accessToken}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
   // }
  
//const redirectResponse=msalInstance.loginRedirect(loginApiRequest);
export default api1;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  
  <ProSidebarProvider>
    <App />
  </ProSidebarProvider>
  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
