import { useState, useEffect } from "react";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import "bootstrap/dist/css/bootstrap.min.css";
import { Col, Container, ListGroup, Row } from "react-bootstrap";

const serverBaseURL =
  "https://cie-poc-saas-command-control.azurewebsites.net/subscribe?sessionId=" +
  generateUUID();

const MotionData = () => {
  const [data, setData] = useState([]);
  const [connectionStatus, setConnectionStatus] = useState("Disconnected");
  

  useEffect(() => {
    const fetchData = async () => {
      await fetchEventSource(`${serverBaseURL}`, {
        method: "POST",
        headers: {
          Accept: "text/event-stream",
        },
        onopen(res) {
          if (res.ok && res.status === 200) {
            console.log("Connection made ", res);
            setConnectionStatus("Connected");
          } else if (
            res.status >= 400 &&
            res.status < 500 &&
            res.status !== 429
          ) {
            console.log("Client side error ", res);
          }
        },
        onmessage(event) {
          console.log(event.data);
          if (event.data != "") {
            const parsedData = JSON.parse(event.data);
            console.log("Parsed:");
            console.log(parsedData);
            setData((data) => [...data, parsedData]);
          }
        },
        onclose() {
          console.log("Connection closed by the server");
          setConnectionStatus("Disconnected");
        },
        onerror(err) {
          console.log("There was an error from server", err);
          setConnectionStatus("Disconnected");
        },
        openWhenHidden: true,
      });
    };
    fetchData();
  }, []);

  return (
    <Container fluid>
      <br></br>
      <Row className="justify-content-md-center">
        <Col lg="1"></Col>
        <Col lg="7">
          <span style={{ fontSize: "20px", fontWeight: "bold" }}>
            Motion Data
          </span>
        </Col>
        <Col lg="4">
          <span>
            <h5
              style={{
                color: connectionStatus === "Connected" ? "green" : "red",
              }}
            >
              Connection Status : {connectionStatus}
            </h5>
          </span>
        </Col>
      </Row>
      <br></br>
      <Row className="justify-content-md-center">
        <Col xs lg="10">
          <ListGroup variant="flush">
            {data.map((key) => {
              if (key.isAlert == true) {
                var variant = "danger";
              } else {
                var variant = "info";
              }
              var formatted = new Date(key.eventTime).toLocaleTimeString();
        
              if (key.deviceType == "motion") {
                return (
                  <ListGroup.Item action variant={variant}>
                    [{formatted}] {variant == "danger" ? "[ALERT] " : ""}|
                    Property : {key.propertyCode} | Room : {key.roomCode} |
                    Motion: {key.motion}{" "}
                  </ListGroup.Item>
                );
              }
              
            })}
          </ListGroup>
        </Col>
      </Row>
    </Container>
  );
};

export default MotionData;

function generateUUID() {
  // Public Domain/MIT
  var d = new Date().getTime(); //Timestamp
  var d2 =
    (typeof performance !== "undefined" &&
      performance.now &&
      performance.now() * 1000) ||
    0; //Time in microseconds since page-load or 0 if unsupported
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = Math.random() * 16; //random number between 0 and 16
    if (d > 0) {
      //Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      //Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
}


